<template>
  <div class="wrapper">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '反馈列表',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="button-box">
      <div class="signup-btn" @click="navToFeedBackPage">我要反馈</div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      v-if="list.length > 0"
    >
      <div
        v-for="(item, i) in list"
        :key="i"
        class="list-item"
        @click="goDetail(item)"
      >
        <div class="right-msg-box">
          <div class="msg-title-box">
            <p class="msg-title">{{ item.title }}</p>
            <p class="msg-time">{{ item.createTime | filterTime }}</p>
          </div>
          <span class="msg-content">
            {{ item.content }}
          </span>
        </div>
        <div class="left-img-box" v-if="item.urlArr.length">
          <van-image
            width="1.5rem"
            height="1.5rem"
            class="img"
            fit="cover"
            :src="itemUrl"
            :key="itemUrl"
            v-for="itemUrl in item.urlArr"
          />
        </div>
      </div>
    </van-list>
    <van-empty v-else description="没有反馈信息" />
  </div>
</template>

<script>
import { getPageList } from "@/api/staff";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,

      pageNum: 1,
      pageSize: 10,

      triggered: false,
      refreshFlag: false,
    };
  },
  components: {TopWrapper },
  computed: {},
  mounted() {
    this.getList();
  },
  filters: {
    filterTime(val) {
      if (!val) return "--:--";
      let valArr = val.split(" ");
      let time = valArr[0];
      let timeArr = time.split("-");
      let month = timeArr[1],
        day = timeArr[2];
      return `${month}-${day}`;
    },
  },
  methods: {
    async getList() {
      const postData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        isSelf: 1,
      };
      await getPageList(postData).then((res) => {
        this.loading = false;
        let records = res.data.records;
        records = records.map((item) => {
          let imgMaterialCodes = item.imgMaterialCodes;
          let urlArr;
          if (imgMaterialCodes) {
            urlArr = imgMaterialCodes.split(",");
          } else {
            urlArr = [];
          }
          return {
            ...item,
            urlArr: urlArr,
          };
        });
        this.list = this.list.concat(records);

        this.pageNum++;
        if (res.data.total <= this.list.length) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      this.getList();
    },
    navToFeedBackPage() {
      this.$router.push("/feedback");
    },
    goDetail(item) {
      this.$router.push({
        path: "/feedbackDetail",
        query: {
          code: item.code,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100%;
  background: #fafafa;
}

.button-box {
  height: 1.14rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0.3rem;
  background: #fff;
  .signup-btn {
    height: 0.42rem;
    font-size: 0.3rem;
    color: #0094ee;
    line-height: 0.42rem;
  }
}
.list-item {
  padding: 0.3rem;
  background: #fff;
  border-radius: 0.2rem;
  margin-top: 0.2rem;
  margin-left: 0.3rem;
  width: 6.9rem;

  /deep/ .van-cell__value {
    display: flex;
    flex-direction: row;
  }
  .left-img-box {
    margin-top: 0.3rem;
    .img {
      margin-right: 0.2rem;
    }
  }
  .right-msg-box {
    flex: 1;
    overflow: hidden;
    .msg-title-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .msg-title {
        font-size: 0.3rem;
        font-weight: 400;
        color: #333333;
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .msg-time {
        font-size: 0.22rem;
        font-weight: 400;
        color: #999999;
      }
    }
    .msg-content {
      margin-top: 0.1rem;
      flex: 1;
      font-size: 0.26rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.37rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
